<template>
  

<div  class="card rounded-lg shadow border border-white bg-white h-100 mb-0 pb-3 overflow-auto" >
    <div class="card-header bg-transparent">
             
           

<div class="row">


    <div class="col">
        
        <h5 class="ml-2 text-secondary font-weight-light"> <span class="fa fa-file-alt"></span> Bon De {{ is_recep ? 'Réception' : 'Sorties'}} : <span class="ml-2 text-success"> {{nomBon}} </span> </h5>
        

        




    </div>

    <div class="col">
         
                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>


                <b-button pill size="sm"  class="m-2 float-right" variant="danger" @click="del_BonClick">
                    <i class="fa fa-trash-alt"></i>
                    <span> Supprimer</span> 
                </b-button>

                <b-button pill size="sm"  class="m-2 float-right" variant="success" @click="clickValiderBon">
                    <i class="fa fa-check"></i>
                    <span> Valider</span> 
                </b-button>

                <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="exportDox">
                    <i class="fa fa-print"></i>
                    <span> Imprimer</span> 
                </b-button>



    </div>

</div>


    </div>




    <div class="row card-body h-100 p-0 m-0">
<div class="p-0">
        <b-button v-b-toggle.collap-categ.collap-articl size="lg" class=" ml-0 mr-0 pl-1 pr-1 float-left text-secondary" variant="link">
        <i class="fa fa-bars"></i>                
        </b-button> 
</div>
  
                <b-collapse id="collap-categ" visible  class="col-2 h-100 mr-3 ml-0 mt-2 p-0">

                            <div class="card border-success h-100 overflow-auto">
                            <div class="card-header  bg-success border-success p-1">
                                            <b-form-input v-model="searchCateg" class="border-info" id="search-cat" size="sm" placeholder="Chercher ..." type="search"></b-form-input>

                            </div>

                            <b-list-group flush >
                            <b-list-group-item  v-for="item in filterCategorie" :key="item.id" button class="p-1" variant="success" @click="art_click(item)">
                                    {{ item.nom }} 
                                    <p> <small>{{item.description}}</small> </p>
                            </b-list-group-item>

                            </b-list-group>
                            </div>



                    
                </b-collapse>


                <div class="col h-100 overflow-auto mt-2 mr-4 ml-1">


            <form @submit.prevent="handleSubmit" >
            <div class="row">
                    <div class="form-group col">
                        <label for="ref" class="form-control-sm mb-0">Nom : </label>
                        <input type="text" v-model="itemForm.ref" id="ref" name="ref" class="form-control form-control-sm"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.ref.$error }" />
                        <div v-if="isSubmitted && !$v.itemForm.ref.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                    </div>
                    

                    <div class="form-group col">
                        <label for="qte_min" class="form-control-sm mb-0 float-left">Quantité :</label>
                        <input type="text" v-model="itemForm.qte" id="qte_min" name="qte_min" class="form-control form-control-sm "
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.qte.$error }" />
                        <div v-if="isSubmitted && !$v.itemForm.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        <div v-if="isSubmitted && !$v.itemForm.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                    </div>

            </div>

          
            <div class="form-group">
            <textarea name="description" id="description"  rows="2"  placeholder="Description ..." class="form-control form-control-sm" v-model="itemForm.description"></textarea>
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-secondary btn-block">Ajouter Entrée</button>
            </div>

            </form>


            

                      <b-table 
        sort-icon-left
        small 
        responsive 

        :fields="fieldsArticle" 
        :items="ArticlesList"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
            </template>
        
        </b-table>


         

</div>

  </div>





    <!-- les trucs public -->




      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
 import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
 import { saveAs } from 'file-saver';

export default {
 props: ['idBon','nomBon','is_recep'],

 
    computed: {
        entet_pag() {

if(this.$store.getters.get_entete)
{
    return this.$store.getters.get_entete;
}
else
{
    return '';
}
},
sousentet_pag() {

if(this.$store.getters.get_sousentete)
{
    return this.$store.getters.get_sousentete;
}
else
{
    return '';
}
},
pied_pag() {

if(this.$store.getters.get_pied)
{
    return this.$store.getters.get_pied;
}
else
{
    return '';
}
},

            user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
     d_gst_entre_val() {
      return this.$store.getters.get_gst_entre_val;
    },

    filterCategorie() {
            return this.listCat.filter(category => {
            return category.nom.toLowerCase().includes(this.searchCateg.toLowerCase())   
      })
    }
    },
    mounted(){

            if(this.idBon)
            {
            this.fetchCategorieList()
            this.fetchListArts(this.idBon)
            }
            else
            {
            this.flashMessage.show({
                                            status: 'error',
                                            title: 'Erreur de Chargement',
                                            message: 'Impossible charger les données de ce bon'
            })
            }

    },
    data () {
		return {


            dateToday: new Date().toJSON().slice(0,10).replace(/-/g,'-'),

            fieldsArticle: [
                {key: 'ref', label: 'Nom'},
                {key: 'qte', label: 'Quantité'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],

            isCodeBarreSearch: false,
            searchCateg:'',
            ArticlesList:[],
            listCat: [],
            itemForm: {
                    id:'',
                    ref:'',
                    description:'',
                    qte:'',
                    prod_entre_id:''},

            isSubmitted: false,
            isLoading: false
		}
    },
    validations: {
            itemForm: {
                ref: {
                    required
                },
                qte: {
                    required,
                    decimal
                },
                prod_entre_id: {
                    required
                }
            }

    },
    methods:{












        exportDox()
        {

        let bntyp =''
        if(this.is_recep==1)
        {
            bntyp = "Bon D'Entrées / Matière Première"
        }
        else
        {
           bntyp = "Bon De Sorties De Matière Première"
        }


        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 35,
                                type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                            text: "Nom",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 45,
                                type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                            text: "Description",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                            text: "Quantité",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),




                    ],
                })]

        this.ArticlesList.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 35,
                                type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                children: [
                                        new TextRun({
                                            text: item.ref,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        spacing: {
                                                    before: 100,
                                                    after: 100,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 45,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                children: [
                                        new TextRun({
                                            text: item.description,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                                spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 20,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                children: [
                                        new TextRun({
                                            text: item.qte.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],

                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),




                    ],
                })

        datrw.push(
        r
        )
        });



        const table = new Table({

            rows:  datrw
        })



        //fin pie
        const doc = new Document({

sections: [
    {



        properties: {
            page: {
                pageNumbers: {
                    start: 1,
                    formatType: PageNumberFormat.DECIMAL,
                },
                margin:{
                            left:500,
                            right:500
                        }
            },
        },
                headers: {
                    default: new Header({
                        children: [
                    new Paragraph({
                                children: [
                        new TextRun({
                            text: this.entet_pag,
                            color: '000000',
                            bold: true,

                            }),
                                ],
                                heading: HeadingLevel.TITLE,
                                alignment: AlignmentType.CENTER,
                            }),

                    new Paragraph({
                                children: [
                        new TextRun({
                            text: this.sousentet_pag,
                            color: '000000',
                            bold: false,
                            font: "Segoe UI"
                            })

                                ],
                            border: {
                            bottom: {
                                    color: "000000",
                                    space: 4,
                                    value: "single",
                                    size: 10,
                                }},
                                heading: HeadingLevel.HEADING_1,
                                alignment: AlignmentType.CENTER,
                            }),




                        ],
                    }),
                },
                footers: {
                    default: new Footer({
                        children: [

                    new Paragraph({
                                children: [
                        new TextRun({
                            text: this.pied_pag,
                            color: '000000',
                            bold: false,
                            })

                                ],
                            border: {
                            top: {
                                    color: "000000",
                                    space: 4,
                                    value: "single",
                                    size: 10,
                                }},
                                heading: HeadingLevel.HEADING_3,
                                alignment: AlignmentType.CENTER,
                            }),

                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        children: ["Page : ", PageNumber.CURRENT],
                                        color: '000000'
                                    }),
                                    new TextRun({
                                        children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        color: '000000'
                                    }),
                                ],
                            }),
                        ],
                    }),
                },


                children: [

new Paragraph({



    children: [

        new TextRun({
        text: bntyp,
        color: '000000',
        bold: false,
        font: "Segoe UI"
    }),
    ],

    heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,

        spacing: {
            after: 200,
            before: 200
        },
}),






new Paragraph({



    children: [
        new TextRun({
        text: "De la part de : ",
        color: '5d83a0',
        bold: false,
        font: "Segoe UI"
    }),
        new TextRun({
        text: this.user_name,
        color: '000000',
        bold: false,
        font: "Segoe UI"
    }),
    new TextRun({
        text: "          Date : ",
        color: '5d83a0',
        bold: false,
        font: "Segoe UI"
    }),

        new TextRun({
        text: this.dateToday,
        color: '000000',
        bold: false,
        font: "Segoe UI"
    }),
    new TextRun({
        text: "          N° : ",
        color: '5d83a0',
        bold: false,
        font: "Segoe UI"
    }),

        new TextRun({
        text: this.nomBon,
        color: '000000',
        bold: false,
        font: "Segoe UI"
    })
    ],

    heading: HeadingLevel.HEADING_3,
     alignment: AlignmentType.CENTER,

        spacing: {
            after: 300,
            before: 100
        },
}),

table

]

    }]

});




            Packer.toBlob(doc).then(blob => {

        saveAs(blob, this.nomBon+".docx");

            });

        },
        art_click(id_art)
        {

            this.itemForm.ref=id_art.nom
            this.itemForm.qte=1
            this.itemForm.description=id_art.description
            this.itemForm.prod_entre_id=id_art.id

        },


        fetchCategorieList: function () {
                    this.isLoading=true;
                    this.$http.get('/prodEntreActif')
                    .then((result) => {
                                this.listCat = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        fetchListArts(id_bn)
        {
                    this.isLoading=true;
                    this.$http.get('/detailProdReception/'+id_bn)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        handleSubmit(){





                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                            this.isLoading=true;

                            this.$http.post('/prodReceptionDetails',{
                            ref:this.itemForm.ref,
                            description:this.itemForm.description,
                            qte:this.itemForm.qte,
                            prod_entre_id:this.itemForm.prod_entre_id,
                            prod_reception_id: this.idBon

                                })
                                .then(response => {

                                    this.isLoading=false;
                                    this.ArticlesList.unshift(response.data.data);

                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })


                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });
                           

                }

        },
        del_click(itemA){

            this.isLoading=true;

            this.$http.delete('/prodReceptionDetails/'+itemA.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.ArticlesList.findIndex(item => item.id === itemA.id)

                    this.ArticlesList.splice(index, 1)
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuReception'
                    });
        },
        del_BonClick() {

                                this.$confirm("Voulez-vous vraiment supprimer ce Bon??")
                                .then(() => {

                                    this.BnDell(this.idBon)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




        },
        BnDell(id_bn){

            this.isLoading=true;

            this.$http.delete('/prodReceptions/'+id_bn)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })

                //goto first page:
                    this.$router.push({ 
                        name: 'menuReception'

                    });




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        clickValiderBon()
        {
        
                                this.$confirm("Voulez-vous vraiment VALIDER ce Bon??")
                                .then(() => {

                                    this.validerBon(this.idBon)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });

        },
        validerBon(id_bn)
        {

            this.isLoading=true;

            this.$http.get('/validerReceptions/'+id_bn+'/1')
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Bon valider',
                message: 'Les données sont validées correctement'
                })

                //goto first page:
                    this.$router.push({ 
                        name: 'menuReception'

                    });

                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Validation',
                message: 'Impossible  de valider les données .. Réessayez !!'
                })
                });

        },

        },
        components: {
                    Loading      
        }


}
</script>

<style>
/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>