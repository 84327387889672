<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





        <div>
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">
        
                <label for="dateDebut" class="mr-sm-2  pt-2 pl-1"> De :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateDebut"
                    placeholder="date fin"
                    v-model="itemFormBon.dateDebut"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
        

                       
                <label for="dateFin" class="mr-sm-2  pt-2 pl-1"> Au :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateFin"
                    placeholder="date fin"
                    v-model="itemFormBon.dateFin"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
      


                                        <button class="btn  btn-info rounded-pill m-2" @click="handleSubmitReception">   
                                        <span class="fa fa-search"></span> Liste Des Réceptions
                                        </button>


                                

                                        <button class="btn  btn-secondary rounded-pill m-2" @click="handleSubmitSorties">   
                                        <span class="fa fa-search"></span> Liste Des Sorties
                                        </button>

                </div>






        </div>





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> {{typebn}}</h4> 


                    <div class="col">


                                    <b-button @click="exportDox" pill size="sm"  class="m-3 float-right" variant="info">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>

                    </div>
            </div>
       



        <b-table 
        
        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"
        responsive 
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
      
        </b-table>

                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>





    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
      computed: {
        entet_pag() {

if(this.$store.getters.get_entete)
{
    return this.$store.getters.get_entete;
}
else
{
    return '';
}
},
sousentet_pag() {

if(this.$store.getters.get_sousentete)
{
    return this.$store.getters.get_sousentete;
}
else
{
    return '';
}
},
pied_pag() {

if(this.$store.getters.get_pied)
{
    return this.$store.getters.get_pied;
}
else
{
    return '';
}
},
      rows_pagin() {
        return this.itemslist.length
      },

    },
    mounted(){

    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            itemFormBon: {
                    dateDebut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    dateFin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            },

            typebn:'',
            itemslist: [],
            fields: [
				{key: 'ref', label: 'Nom', sortable: true},
                {key: 'total_qte', label: 'Total Quantité', sortable: true},

            ],

            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {
                dateDebut: {
                    required
                },
                dateFin: {
                    required
                }
            }

    },
    methods :{
        

                exportDox()
                        {




                        let datrw =[new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 5000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                       
                                                        children: [
                                                                new TextRun({
                                                                text: "Nom",
                                                                color: '0f31b5',
                                                                bold: false,
                                                                font: "Segoe UI"
                                                                }),
                                                            ],
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                        new TableCell({
                                            width: {
                                                size: 5000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        
                                                        children: [
                                                            new TextRun({
                                                            text: "Total Quantité",
                                                            color: '0f31b5',
                                                            bold: false,
                                                            font: "Segoe UI"
                                                            }),
                                                        ],
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                    ],
                                })]

                        this.itemslist.forEach(function(item){

                        let r = new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 5000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        children: [
                                        new TextRun({
                                            text: item.ref,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                        new TableCell({
                                            width: {
                                                size: 5000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        
                                                        children: [
                                                            new TextRun({
                                                                text: item.total_qte.toString(),
                                                                color: '000000',
                                                                bold: true,
                                                                }),
                                                                    ],
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),


                                    ],
                                })

                        datrw.push(
                        r
                        )
                        });


                        const table = new Table({

                            rows:  datrw
                        })


        //fin pie
        const doc = new Document({

sections: [
    {



        properties: {
            page: {
                pageNumbers: {
                    start: 1,
                    formatType: PageNumberFormat.DECIMAL,
                },
                margin:{
                            left:500,
                            right:500
                        }
            },
        },
                headers: {
                    default: new Header({
                        children: [
                    new Paragraph({
                                children: [
                        new TextRun({
                            text: this.entet_pag,
                            color: '000000',
                            bold: true,

                            }),
                                ],
                                heading: HeadingLevel.TITLE,
                                alignment: AlignmentType.CENTER,
                            }),

                    new Paragraph({
                                children: [
                        new TextRun({
                            text: this.sousentet_pag,
                            color: '000000',
                            bold: false,
                            font: "Segoe UI"
                            })

                                ],
                            border: {
                            bottom: {
                                    color: "000000",
                                    space: 4,
                                    value: "single",
                                    size: 10,
                                }},
                                heading: HeadingLevel.HEADING_1,
                                alignment: AlignmentType.CENTER,
                            }),




                        ],
                    }),
                },
                footers: {
                    default: new Footer({
                        children: [

                    new Paragraph({
                                children: [
                        new TextRun({
                            text: this.pied_pag,
                            color: '000000',
                            bold: false,
                            })

                                ],
                            border: {
                            top: {
                                    color: "000000",
                                    space: 4,
                                    value: "single",
                                    size: 10,
                                }},
                                heading: HeadingLevel.HEADING_3,
                                alignment: AlignmentType.CENTER,
                            }),

                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        children: ["Page : ", PageNumber.CURRENT],
                                        color: '000000'
                                    }),
                                    new TextRun({
                                        children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        color: '000000'
                                    }),
                                ],
                            }),
                        ],
                    }),
                },


                children: [

new Paragraph({



    children: [

        new TextRun({
        text: this.typebn,
        color: '000000',
        bold: false,
        font: "Segoe UI"
    }),
    ],

    heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.CENTER,

        spacing: {
            after: 300,
            before: 300
        },
}),
                
new Paragraph({



    children: [

        new TextRun({
        text: "De : "+this.itemFormBon.dateDebut+" Au "+this.itemFormBon.dateFin,
        color: '000000',
        bold: false,
        font: "Segoe UI"
    }),

    ],

    heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.CENTER,

        spacing: {
            after: 300,
            before: 300
        },
}),
                
table

]

    }]

});






                            Packer.toBlob(doc).then(blob => {

                        saveAs(blob, "Entrés_articles.docx");

                            });

                },

                handleSubmitReception(){


                    

                        this.isSubmitted = true;

                        this.$v.$touch();
                        if (this.$v.$invalid) {
                        this.flashMessage.show({
                        status: 'info',
                        title: 'Vérifiez les données et Reésayez !!',
                        })
                        }
                        else 
                        {

                        this.typebn = "Réceptions Des Entrées / Matière Première"
                        
                        this.fetchList(1,this.itemFormBon.dateDebut,this.itemFormBon.dateFin)
                        }
                },



                handleSubmitSorties(){



                        this.isSubmitted = true;

                        this.$v.$touch();
                        if (this.$v.$invalid) {
                        this.flashMessage.show({
                        status: 'info',
                        title: 'Vérifiez les données et Reésayez !!',
                        })
                        }
                        else 
                        {
                        this.typebn = "Sorties De La Matière Première"
                        this.fetchList(0,this.itemFormBon.dateDebut,this.itemFormBon.dateFin)
                        }
                },




                fetchList: function (typ,deb,fin) {
                            this.isLoading=true;
                            
                            this.$http.get('/receptSortQteRapp/'+typ+'/'+deb+'/'+fin)
                            .then((result) => {
                                        this.itemslist = result.data.data
                                        this.isLoading=false;
                            })
                            .catch(error=> {
                                        console.log(error)
                                        this.isLoading=false;
                                        this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur de Chargement',
                                        message: 'Impossible charger les données.. Actualisez et Réessayez'
                                        })
                            });
                }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>