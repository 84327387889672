<template>
<div>
  
                        
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Bons De Sorties ( Validés )    </h4> 



                    <div class="col">
                                    <b-button pill size="sm"  class="m-2 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

                                    <b-button pill size="sm"  class="m-3 float-right" variant="info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actualiser</span> 
                                    </b-button>
                    </div>
            </div>
       



        <b-table 
                id="main-table"
        :per-page="perPage"
        :current-page="currentPage"
        responsive 
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button  pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-warning" @click="devalider_click(row.item)">
                                <i class="fa fa-check"></i>
                        
                    </b-button>
                    <b-button  pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-info" @click="edit_devalider_click(row.item)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>
                    <b-button pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-info" @click="Ckick_exportDox(row.item)">
                            <i class="fa fa-print"></i>
                    
                    </b-button>
                    <b-button pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-info" @click="fetchListArts(row.item)">
                            <i class="fa fa-bars"></i>
                    
                    </b-button>
                </template>
        
        </b-table>
                        <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>
      </div>


    <!-- le modal -->
    <b-modal id="detailModal" :title=curentBN size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsArticle" 
        :items="ArticlesList"
        >

        
        </b-table>
    </b-modal>

    <!-- les trucs public -->

<loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
 import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
 import { saveAs } from 'file-saver';

export default {
    computed: {
        entet_pag() {

if(this.$store.getters.get_entete)
{
    return this.$store.getters.get_entete;
}
else
{
    return '';
}
},
sousentet_pag() {

if(this.$store.getters.get_sousentete)
{
    return this.$store.getters.get_sousentete;
}
else
{
    return '';
}
},
pied_pag() {

if(this.$store.getters.get_pied)
{
    return this.$store.getters.get_pied;
}
else
{
    return '';
}
},

              rows_pagin() {
        return this.itemslist.length
      },

    },
    mounted(){
        this.fetchList()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            fieldsArticle: [
                {key: 'ref', label: 'Nom'},
                {key: 'qte', label: 'Quantité'},
                {key: 'description', label: 'Description'}
            ],
            ArticlesList:[],

            curentBN:'',

            itemslist: [],

            fields: [
                {key: 'nom', label: 'N°', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'valider', label: 'Valider', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                {key: 'user', label: 'Utilisateur', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs

            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                }
            }
    },
    methods :{

        Ckick_exportDox(rowitem)
        {
                            this.isLoading=true;
                            this.$http.get('/detailProdReception/'+rowitem.id)
                            .then((result) => {
                                        this.ArticlesList = result.data.data
                                        this.isLoading=false;


                                        //telecharger rapp
                                        this.exportDox(rowitem)

                            })
                            .catch(error=> {
                                        console.log(error)
                                        this.isLoading=false;
                                        this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur de Chargement',
                                        message: 'Impossible charger les données.. Actualisez et Réessayez'
                                        })
                            });
        },
        exportDox(rowitem)
        {


            let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 35,
                                type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                            text: "Nom",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                                spacing: {
                                                before: 100,
                                                after: 100
                                            },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 45,
                                type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                            text: "Description",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 20,
                                type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                            text: "Quantité",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),




                    ],
                })]

        this.ArticlesList.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 35,
                                type: WidthType.PERCENTAGE,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                children: [
                                        new TextRun({
                                            text: item.ref,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        spacing: {
                                                    before: 100,
                                                    after: 100,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 45,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                children: [
                                        new TextRun({
                                            text: item.description,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                                spacing: {
                                                    before: 10,
                                                    after: 10,
                                                    left: 10,
                                                    right:10
                                                },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 20,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                children: [
                                        new TextRun({
                                            text: item.qte.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],

                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),




                    ],
                })

        datrw.push(
        r
        )
        });




        const table = new Table({

            rows:  datrw
        })



        //fin pie
        const doc = new Document({

sections: [
    {



        properties: {
            page: {
                pageNumbers: {
                    start: 1,
                    formatType: PageNumberFormat.DECIMAL,
                },
                margin:{
                            left:500,
                            right:500
                        }
            },
        },
                headers: {
                    default: new Header({
                        children: [
                    new Paragraph({
                                children: [
                        new TextRun({
                            text: this.entet_pag,
                            color: '000000',
                            bold: true,

                            }),
                                ],
                                heading: HeadingLevel.TITLE,
                                alignment: AlignmentType.CENTER,
                            }),

                    new Paragraph({
                                children: [
                        new TextRun({
                            text: this.sousentet_pag,
                            color: '000000',
                            bold: false,
                            font: "Segoe UI"
                            })

                                ],
                            border: {
                            bottom: {
                                    color: "000000",
                                    space: 4,
                                    value: "single",
                                    size: 10,
                                }},
                                heading: HeadingLevel.HEADING_1,
                                alignment: AlignmentType.CENTER,
                            }),




                        ],
                    }),
                },
                footers: {
                    default: new Footer({
                        children: [

                    new Paragraph({
                                children: [
                        new TextRun({
                            text: this.pied_pag,
                            color: '000000',
                            bold: false,
                            })

                                ],
                            border: {
                            top: {
                                    color: "000000",
                                    space: 4,
                                    value: "single",
                                    size: 10,
                                }},
                                heading: HeadingLevel.HEADING_3,
                                alignment: AlignmentType.CENTER,
                            }),

                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        children: ["Page : ", PageNumber.CURRENT],
                                        color: '000000'
                                    }),
                                    new TextRun({
                                        children: [" Sur ", PageNumber.TOTAL_PAGES],
                                        color: '000000'
                                    }),
                                ],
                            }),
                        ],
                    }),
                },



                children: [

new Paragraph({



    children: [

        new TextRun({
        text: "Bon De Sorties De Matière Première",
        color: '000000',
        bold: false,
        font: "Segoe UI"
    }),
    ],

    heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,

        spacing: {
            after: 200,
            before: 200
        },
}),




new Paragraph({



    children: [
        new TextRun({
        text: "De la part de : ",
        color: '5d83a0',
        bold: false,
        font: "Segoe UI"
    }),
        new TextRun({
        text: rowitem.user,
        color: '000000',
        bold: false,
        font: "Segoe UI"
    }),
    new TextRun({
        text: "          Date : ",
        color: '5d83a0',
        bold: false,
        font: "Segoe UI"
    }),

        new TextRun({
        text: rowitem.date,
        color: '000000',
        bold: false,
        font: "Segoe UI"
    }),
            new TextRun({
                text: "          N° : ",
                color: '5d83a0',
                bold: false,
                font: "Segoe UI"
            }),

                new TextRun({
                text: rowitem.nom,
                color: '000000',
                bold: false,
                font: "Segoe UI"
            })
    ],

    heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.CENTER,

        spacing: {
            after: 300,
            before: 100
        },
}),

table

]

    }]

});




            Packer.toBlob(doc).then(blob => {

        saveAs(blob, rowitem.nom+".docx");

            });

        },

        edit_devalider_click(item) {

                this.$confirm("Voulez-vous vraiment devalider et modifier ce Bon ??")
                 .then(() => {

                        this.BnDevalider(item.id,true)


                })
                .catch(error=> {
                console.log(error)

                });


        },
        devalider_click(item) {

                                this.$confirm("Voulez-vous vraiment Devalider ce Bon??")
                                .then(() => {

                                    this.BnDevalider(item.id,false)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




        },
        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/bnReceptionType/1/0')
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        BnDevalider(id_bn,edit){

            this.isLoading=true;

                this.$http.get('/validerReceptions/'+id_bn+'/0')
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Bon Devalidé',
                message: 'Les données sont devalidées correctement'
                })

                    if(edit)
                    {
                                            this.$router.push({ 
                                            name: 'prodReception', 
                                            params: { 
                                            ResivedId: id_bn
                                            } 
                                            });
                    }
                    else
                    {
                                    let index = this.itemslist.findIndex(item => item.id === id_bn)
                                    this.itemslist.splice(index, 1)

                    }




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de devalider les données .. Réessayez !!'
                })
                });

        },
        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuReception'
                    });
        },

        fetchListArts(row_bn)
        {
                    this.isLoading=true;
                    this.$http.get('/detailProdReception/'+row_bn.id)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;


                                this.curentBN=row_bn.nom + " ( "+row_bn.date+" )"
                                this.$root.$emit('bv::show::modal', 'detailModal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>